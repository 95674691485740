import { motion, useAnimation, useScroll } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Parallax } from "react-scroll-parallax";

import Timeline from "../components/Timeline";
import { BackgroundGradientAnimation } from "../components/BackgroundGradientAnimation";
import CardAnimated from "../components/elements/CardAnimated";
import MagentButton from "../components/elements/MagnetButton";
import ProjectCards from "../components/sections/HospitalCards";
import TestimonialSection from "../components/sections/TestimonialSection";
import Doctors from "../components/sections/Doctors";

import { useTranslation } from "react-i18next";
import "../components/i18next";

const Home = () => {
  const { t, i18n } = useTranslation();
  const { scrollYProgress } = useScroll();

  // refs
  const sectionMainRef = useRef(null);

  const refTestimonials = useRef(null);
  const refBigPicture = useRef(null);
  const refAboutUs = useRef(null);
  const refFeaturedContent = useRef(null);
  const refWordCloud = useRef(null);
  const refOurQuality = useRef(null);
  const refOurServices = useRef(null);
  const refContact = useRef(null);
  const refCTATitle = useRef(null);
  const refCallNow = useRef(null);
  const refFooter = useRef(null);

  // controls
  const controlsTestimonials = useAnimation();
  const controlsAboutUs = useAnimation();
  const controlsFeaturedContent = useAnimation();
  const controlsWordCloud = useAnimation();
  const controlsOurQuality = useAnimation();
  const controlsOurServices = useAnimation();
  const controlsContact = useAnimation();
  const controlsCTATitle = useAnimation();
  const controlsCallNow = useAnimation();
  const controlsFooter = useAnimation();

  // states
  const [showScrollDownButton, setShowScrollDownButton] = useState(true);

  const [animatedTestimonials, setanimatedTestimonials] = useState(false);
  const [animatedAboutUs, setanimatedAboutUs] = useState(false);
  const [animatedFeaturedContent, setanimatedFeaturedContent] = useState(false);
  const [animatedWordCloud, setanimatedWordCloud] = useState(false);
  const [animatedOurQuality, setanimatedOurQuality] = useState(false);
  const [animatedOurServices, setanimatedOurServices] = useState(false);
  const [animatedContact, setanimatedContact] = useState(false);
  const [animatedCTATitle, setanimatedCTATitle] = useState(false);
  const [animatedCallNow, setanimatedCallNow] = useState(false);
  const [animatedFooter, setanimatedFooter] = useState(false);

  // ======================================================================== //
  // 🟩 Effects

  useEffect(() => {
    const handleScroll = () => {
      if (!animatedTestimonials && refTestimonials.current) {
        const getTopValue = refTestimonials.current.getBoundingClientRect().top;
        const threshold = window.innerHeight / 2;

        if (getTopValue < threshold) {
          controlsTestimonials.start("visible");
          setanimatedTestimonials(true);
        }
      }

      if (!animatedAboutUs && refAboutUs.current) {
        const getTopValue = refAboutUs.current.getBoundingClientRect().top;
        const threshold = window.innerHeight / 2;

        if (getTopValue < threshold) {
          controlsAboutUs.start("visible");
          setanimatedAboutUs(true);
        }
      }

      if (!animatedFeaturedContent && refFeaturedContent.current) {
        const getTopValue = refFeaturedContent.current.getBoundingClientRect().top;
        const threshold = window.innerHeight / 2;

        if (getTopValue < threshold) {
          controlsFeaturedContent.start("visible");
          setanimatedFeaturedContent(true);
        }
      }

      if (!animatedWordCloud && refWordCloud.current) {
        const getTopValue = refWordCloud.current.getBoundingClientRect().top;
        const threshold = window.innerHeight / 2;

        if (getTopValue < threshold) {
          controlsWordCloud.start("visible");
          setanimatedWordCloud(true);
        }
      }

      if (!animatedOurQuality && refOurQuality.current) {
        const getTopValue = refOurQuality.current.getBoundingClientRect().top;
        const threshold = window.innerHeight / 2;

        if (getTopValue < threshold) {
          controlsOurQuality.start("visible");
          setanimatedOurQuality(true);
        }
      }

      if (!animatedOurServices && refOurServices.current) {
        const getTopValue = refOurServices.current.getBoundingClientRect().top;
        const threshold = window.innerHeight / 2;

        if (getTopValue < threshold) {
          controlsOurServices.start("visible");
          setanimatedOurServices(true);
        }
      }

      if (!animatedContact && refContact.current) {
        const getTopValue = refContact.current.getBoundingClientRect().top;
        const threshold = window.innerHeight / 2;

        if (getTopValue < threshold) {
          controlsContact.start("visible");
          setanimatedContact(true);
        }
      }

      if (!animatedCTATitle && refCTATitle.current) {
        const getTopValue = refCTATitle.current.getBoundingClientRect().top;
        const threshold = window.innerHeight / 2;

        if (getTopValue < threshold) {
          controlsCTATitle.start("visible");
          setanimatedCTATitle(true);
        }
      }

      if (!animatedCallNow && refCallNow.current) {
        const getTopValue = refCallNow.current.getBoundingClientRect().top;
        const threshold = window.innerHeight / 2;

        if (getTopValue < threshold) {
          controlsCallNow.start("visible");
          setanimatedCallNow(true);
        }
      }

      if (!animatedFooter && refFooter.current) {
        const getTopValue = refFooter.current.getBoundingClientRect().top;
        const threshold = window.innerHeight - 200;

        if (getTopValue < threshold) {
          controlsFooter.start("visible");
          setanimatedFooter(true);
        }
      }

      //
      // Scroll button
      //
      const currentScrollY = window.scrollY;
      // console.log(currentScrollY);

      if (currentScrollY > 200) {
        setShowScrollDownButton(false);
      } else {
        setShowScrollDownButton(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    animatedTestimonials,
    animatedAboutUs,
    animatedFeaturedContent,
    animatedWordCloud,
    animatedOurQuality,
    animatedOurServices,
    animatedContact,
    animatedCTATitle,
    animatedCallNow,
    animatedFooter,
    controlsTestimonials,
    controlsAboutUs,
    controlsFeaturedContent,
    controlsWordCloud,
    controlsOurQuality,
    controlsOurServices,
    controlsContact,
    controlsCTATitle,
    controlsCallNow,
    controlsFooter,
  ]);

  // ======================================================================== //
  // 🟨 Functions

  const scrollToBelowSection = () => {
    // example: scrolling to Why Us section

    if (refBigPicture.current) {
      refBigPicture.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  //
  // # Home Page Sections
  // - Logo and slogan
  // - Why us
  // - Experience
  // - Quality
  // - Services
  // - Testimonials
  // - Partners
  // - Stats
  // - Footer (contact us)
  //

  const cardVariantsA = {
    offscreen: {
      y: 300,
      opacity: 0,
    },
    onscreen: {
      y: 50,
      opacity: 1,
      rotate: -5,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  const cardVariantsB = {
    offscreen: {
      y: 300,
      opacity: 0,
    },
    onscreen: {
      y: 50,
      opacity: 1,
      rotate: 5,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
      scale: 0.9,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  const cardVariantsFade = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  const background = `linear-gradient(306deg, 340}, 10)`;

  const controls = useAnimation();

  const text1 = t("mainHeadLine1").split(" ");
  const text2 = t("mainHeadLine2").split(" ");

  const items = [t("intro1"), t("intro2")];

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1169);
  const [isSmallMobile, setIsSmallMobile] = useState(window.innerWidth < 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1169);
      setIsSmallMobile(window.innerWidth < 480);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const parallaxRef = useRef(null);

  useEffect(() => {
    const parallaxElements = document.querySelectorAll(".parallaxOnVisible");

    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        }
      },
      { threshold: 0.5, rootMargin: "20%" }
    );

    parallaxElements.forEach((el) => observer.observe(el));

    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      parallaxElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  //
  //
  // Language
  const languages = [
    { code: "mn", flag: "🇲🇳" },
    { code: "kz", flag: "🇰🇿" },
    { code: "ru", flag: "🇷🇺" },
  ];
  const buttonRef = useRef(null);

  // Get language from the URL
  useEffect(() => {
    const languageFromUrl = window.location.pathname.split("/")[1];
    const supportedLanguages = ["ru", "kz"];

    if (supportedLanguages.includes(languageFromUrl)) {
      i18n.changeLanguage(languageFromUrl);
    }
  }, [i18n]);

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);

    // Update the URL based on the new language selected
    if (newLanguage === "mn") {
      window.history.pushState({}, "", "/");
    } else if (newLanguage === "ru") {
      window.history.pushState({}, "", "/ru");
    } else if (newLanguage === "kz") {
      window.history.pushState({}, "", "/kz");
    } else {
      // Default to root for other cases, like "en"
      window.history.pushState({}, "", "/");
    }
  };
  // Language
  //
  //

  return (
    <div ref={sectionMainRef} className="min-h-[700px] w-full overflow-hidden">
      <div className="pl-[30px] pr-[30px]">
        <div
          className="relative flex justify-center items-center mt-[30px] mb-[30px] rounded-3xl overflow-hidden max-w-[1600px] ml-auto mr-auto"
          style={{ boxShadow: "0px 16px 40px rgb(148 189 212)" }}
        >
          <motion.div
            className="bg-primary-300 bg-opacity-35"
            style={{
              width: "1000px",
              height: "1000px",
              position: "absolute",
              borderRadius: "50%",
              overflow: "hidden",
              zIndex: "20",
            }}
            animate={controls}
            initial={{ top: -700 }}
          >
            {/* Your content goes here */}
          </motion.div>

          {isMobile ? (
            <div className="absolute top-0 z-50 flex w-full justify-between">
              <div className="flex p-2 justify-start items-start">
                <motion.div
                  initial={{ opacity: 0, y: "-100%" }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: "20%" }}
                  transition={{ duration: 0.5 }}
                  className="relative mx-2"
                  ref={buttonRef}
                >
                  <a href="https://www.facebook.com/miracle.medical.consulting" target="_blank" rel="noreferrer">
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      className="w-9 h-9 rounded-full bg-[#02aed9] drop-shadow-md right-[-40px] border border-3 border-white cursor-pointer"
                    >
                      <div className="text-xs text-white justify-center items-center flex">
                        <svg class="w-[16px] h-[16px] fill-[#fff]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
                        </svg>
                      </div>
                    </motion.button>
                  </a>
                </motion.div>

                {/* Language switcher */}
                <motion.div
                  initial={{ opacity: 0, y: "-100%" }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: "20%" }}
                  transition={{ duration: 0.5 }}
                  className="relative"
                  ref={buttonRef}
                >
                  <div className="flex flex-row space-x-2">
                    {languages.map((lang) => {
                      const isActive = window.location.pathname === `/${lang.code}` || (window.location.pathname === "/" && lang.code === "mn");
                      return (
                        <motion.div className="flex flex-col items-center" key={lang.code}>
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 800, damping: 20 }}
                            onClick={() => changeLanguage(lang.code)}
                            className={`w-9 h-9 rounded-full border border-3 border-white bg-color cursor-pointer ${
                              isActive ? "bg-[#02aed9]" : "bg-white"
                            }`}
                          >
                            <div className="text-xs text-white justify-center items-center flex">
                              <span className="text-lg">{lang.flag}</span>
                            </div>
                          </motion.button>
                          <span className="uppercase text-black text-[8px] text-center mt-1">{lang.code}</span>
                        </motion.div>
                      );
                    })}
                  </div>
                </motion.div>
              </div>
              {isSmallMobile ? (
                <div className="flex p-2 justify-center items-start">
                  <a
                    href={t("phoneNumberCode")}
                    style={{ border: "1px solid #fff" }}
                    className="mx-0 cursor-pointer inline-block rounded-full px-1 py-1 text-[7px] text-master-100 font-medium uppercase leading-normal text-main-200 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-primary-200 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200"
                  >
                    {t("phoneNumber")}
                  </a>
                </div>
              ) : (
                <div className="flex p-3 justify-center items-center">
                  <a
                    href={t("phoneNumberCode")}
                    className="mx-2 cursor-pointer flex flex-row justify-center items-center rounded-full px-6 py-2 text-lg font-medium uppercase leading-normal text-primary-300 hover:text-master-100 font-['Ubuntu_Condensed'] transition duration-150 ease-in-out hover:border-primary-accent-100 bg-primary-100 shadow-[0px 6px 20px #bcbcbc] hover:bg-primary-200 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200"
                  >
                    <svg class="w-[18px] h-[18px] fill-[#fff] mr-4" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"></path>
                    </svg>
                    {t("phoneNumber")}
                  </a>
                </div>
              )}
            </div>
          ) : (
            <div className="absolute top-0 z-50 flex w-full justify-between">
              <div className="flex p-3 justify-start items-start">
                <motion.div
                  initial={{ opacity: 0, y: "-100%" }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: "20%" }}
                  transition={{ duration: 0.5 }}
                  className="relative mx-2"
                  ref={buttonRef}
                >
                  <a href="https://www.facebook.com/miracle.medical.consulting" target="_blank" rel="noreferrer">
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      className="w-9 h-9 rounded-full bg-[#02aed9] drop-shadow-md right-[-40px] border border-3 cursor-pointer"
                    >
                      <div className="text-xs text-white justify-center items-center flex">
                        <svg class="w-[16px] h-[16px] fill-[#fff]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
                        </svg>
                      </div>
                    </motion.button>
                  </a>
                </motion.div>

                {/* Language switcher */}
                <motion.div
                  initial={{ opacity: 0, y: "-100%" }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: "20%" }}
                  transition={{ duration: 0.5 }}
                  className="relative"
                  ref={buttonRef}
                >
                  <div className="flex flex-row space-x-2">
                    {languages.map((lang) => {
                      const isActive = window.location.pathname === `/${lang.code}` || (window.location.pathname === "/" && lang.code === "mn");
                      return (
                        <motion.div className="flex flex-col items-center" key={lang.code}>
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 800, damping: 20 }}
                            onClick={() => changeLanguage(lang.code)}
                            className={`w-9 h-9 rounded-full border border-3 border-white bg-color cursor-pointer ${
                              isActive ? "bg-[#02aed9]" : "bg-white"
                            }`}
                          >
                            <div className="text-xs text-white justify-center items-center flex">
                              <span className="text-lg">{lang.flag}</span>
                            </div>
                          </motion.button>
                          <span className="uppercase text-black text-[8px] text-center mt-1">{lang.code}</span>
                        </motion.div>
                      );
                    })}
                  </div>
                </motion.div>
              </div>
              <div className="flex p-3 justify-center items-center">
                <a
                  href={t("phoneNumberCode")}
                  className="mx-2 cursor-pointer flex flex-row justify-center items-center rounded-full px-6 py-2 text-lg font-medium uppercase leading-normal text-primary-300 hover:text-master-100 font-['Ubuntu_Condensed'] transition duration-150 ease-in-out hover:border-primary-accent-100 bg-primary-100 shadow-[0px 6px 20px #bcbcbc] hover:bg-primary-200 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200"
                >
                  <svg class="w-[18px] h-[18px] fill-[#fff] mr-4" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"></path>
                  </svg>
                  {t("phoneNumber")}
                </a>
              </div>
            </div>
          )}

          <BackgroundGradientAnimation>
            <div className="absolute z-40 inset-0 pointer-events-none flex items-start">
              <div className="absolute flex justify-center items-center w-full py-16 font-bold flex-col z-40">
                <div className="relative z-10 flex flex-col justify-center items-center">
                  <img src="images/logo.png" alt="Miracle - Medical Consulting" className="w-[100px]" />
                  <h1 className="uppercase text-secondary-100 text-5xl">Miracle</h1>
                  <span className="uppercase text-master-100 text-lg">Medical Consulting</span>
                  <span className="uppercase text-primary-100 text-xs">Since 2018</span>
                </div>
              </div>

              {isMobile ? (
                <div className="flex h-full flex-col align-bottom justify-end pb-[50px]">
                  <h1
                    className="text-master-200 text-center font-['Ubuntu_Condensed'] uppercase text-[7vw] max-w-[1169px]:text-[100px] px-4 py-12 font-medium"
                    style={{ lineHeight: "1" }}
                  >
                    <motion.div
                      className="mb-6 flex justify-center"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ duration: 0.5, type: "spring", stiffness: 120 }}
                    >
                      <img src="images/home-doc-welcome.png" alt="Miracle - Medical Consulting" style={{ height: "160px" }} />
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, y: -50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, type: "spring", stiffness: 120 }}
                    >
                      <img src="images/turkey_flag.png" alt="Miracle - Medical Consulting" className="w-[30px] m-auto mb-2 rounded" />
                    </motion.div>
                    <span className="text-secondary-100">
                      {" "}
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 0.25,
                          delay: 0.1,
                        }}
                      >
                        {text1}{" "}
                      </motion.span>
                    </span>{" "}
                    {text2.map((el, i) => (
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 0.25,
                          delay: i * 0.2,
                        }}
                        key={i}
                      >
                        {el}{" "}
                      </motion.span>
                    ))}
                  </h1>
                </div>
              ) : (
                <motion.section ref={refFooter} className="flex justify-end items-end bg-center bg-no-repeat bg-cover h-svh">
                  <Parallax className="w-3/5" translateY={[-20, 20]} speed={110}>
                    <h1
                      className="text-master-200 font-['Ubuntu_Condensed'] uppercase text-[7vw] max-w-[1169px]:text-[100px] p-12 font-medium"
                      style={{ lineHeight: "1" }}
                    >
                      <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, type: "spring", stiffness: 120 }}
                      >
                        <img src="images/turkey_flag.png" alt="Miracle - Medical Consulting" className="floatingContainer w-[100px] rounded" />
                      </motion.div>
                      <span className="text-secondary-100">
                        {" "}
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{
                            duration: 0.25,
                            delay: 0.1,
                          }}
                        >
                          {text1}{" "}
                        </motion.span>
                      </span>{" "}
                      {text2.map((el, i) => (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{
                            duration: 0.25,
                            delay: i * 0.2,
                          }}
                          key={i}
                        >
                          {el}{" "}
                        </motion.span>
                      ))}
                    </h1>
                  </Parallax>

                  <Parallax className="w-2/5" translateY={[-20, 20]} translateX={[-20, 20]} speed={110}>
                    <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.5, type: "spring", stiffness: 120 }}>
                      <img src="images/home-doc-welcome.png" alt="Miracle - Medical Consulting" />
                    </motion.div>
                  </Parallax>
                </motion.section>
              )}
            </div>
          </BackgroundGradientAnimation>
        </div>
      </div>

      <motion.div
        ref={refBigPicture}
        className="card-container flex-col"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.3 }}
      >
        <div className="absolute w-[960px]">
          <Parallax translateX={[0, 0]} translateY={[10, -100]} speed={110} easing={"easeInCubic"} opacity={[0.2, 0.8]} rotate={[0, 180]}>
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#00A5E7"
                d="M50.6,-74.7C65.8,-69.1,78.4,-55.3,81.7,-39.7C85.1,-24.2,79.2,-6.8,76.1,10.6C73.1,28,72.8,45.4,64.1,55.7C55.4,66,38.3,69.2,23.1,69.4C7.9,69.5,-5.4,66.6,-19.7,63.9C-34,61.1,-49.3,58.5,-61.3,50.1C-73.3,41.7,-82,27.3,-81.5,13.3C-81,-0.7,-71.3,-14.4,-63.4,-27.9C-55.6,-41.3,-49.6,-54.6,-39.3,-62.4C-29,-70.3,-14.5,-72.8,1.6,-75.3C17.7,-77.8,35.5,-80.3,50.6,-74.7Z"
                transform="translate(100 100)"
              />
            </svg>
          </Parallax>
        </div>

        <div className="splash" style={{ background }} />

        <div className="card font-['Oswald'] relative">
          <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.3 }}>
            <motion.div variants={cardVariantsScaled}>
              <img src="images/confused.png" alt="Miracle - Medical Consulting" className="w-[400px]" />
            </motion.div>
          </motion.div>

          <motion.div variants={cardVariantsA}>
            <div className="flex flex-col fontMax" style={{ lineHeight: 1.2 }}>
              <span>{t("question1")}</span>
              <div className="stack" style={{ "--stacks": 3 }}>
                <span style={{ "--index": 0 }} className="fontMax">
                  {" "}
                  {t("question1a")}
                </span>
                <span style={{ "--index": 1 }} className="fontMax">
                  {" "}
                  {t("question1a")}
                </span>
                <span style={{ "--index": 2 }} className="fontMax">
                  {" "}
                  {t("question1a")}
                </span>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>

      <motion.div className="card-container flex-col" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.3 }}>
        <div className="absolute w-[1260px]">
          <Parallax translateX={[-20, 120]} speed={10} easing={"easeInCubic"} opacity={[0.2, 0.8]} rotate={[0, 120]}>
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#FFAA9B"
                d="M44.8,-67.9C54,-63.8,54.7,-44.5,60.8,-28.5C66.9,-12.5,78.6,0.2,77.8,11.7C76.9,23.2,63.7,33.4,52.3,43.9C41,54.4,31.7,65.2,19.4,71.2C7.1,77.2,-8.2,78.4,-16.7,70C-25.3,61.5,-27.2,43.4,-33.5,31.6C-39.8,19.8,-50.5,14.3,-60.2,3.4C-69.9,-7.4,-78.5,-23.6,-77,-38.6C-75.5,-53.7,-63.9,-67.6,-49.4,-69.2C-34.9,-70.8,-17.4,-60.2,0.2,-60.5C17.8,-60.8,35.6,-71.9,44.8,-67.9Z"
                transform="translate(100 100)"
              />
            </svg>
          </Parallax>
        </div>

        <div className="splash" style={{ background }} />

        <motion.div className="card font-['Oswald'] relative" variants={cardVariantsB}>
          <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.3 }}>
            <motion.div variants={cardVariantsScaled}>
              <img src="images/worried.png" alt="Miracle - Medical Consulting" className="w-[400px]" />
            </motion.div>
          </motion.div>

          <div className="flex flex-col fontMax" style={{ lineHeight: 1.2 }}>
            <span>{t("question2")}</span>
            <div className="stack" style={{ "--stacks": 3 }}>
              <span style={{ "--index": 0 }} className="fontMax">
                {" "}
                {t("question2a")}
              </span>
              <span style={{ "--index": 1 }} className="fontMax">
                {" "}
                {t("question2a")}
              </span>
              <span style={{ "--index": 2 }} className="fontMax">
                {" "}
                {t("question2a")}
              </span>
            </div>
          </div>
        </motion.div>
      </motion.div>

      <motion.div className="card-container flex-col" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.3 }}>
        <div className="absolute w-[1260px]">
          <Parallax translateX={[20, -120]} speed={10} easing={"easeInCubic"} opacity={[0.2, 0.8]} rotate={[0, 120]}>
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#02AED9"
                d="M35.2,-56.2C42.8,-49.8,44.3,-35.4,53.5,-22.4C62.6,-9.4,79.4,2.2,78.4,11.5C77.3,20.7,58.5,27.6,45.8,36.3C33,45,26.2,55.5,16.6,59.8C7,64.2,-5.4,62.4,-16,57.8C-26.7,53.2,-35.6,45.9,-43.7,37.4C-51.7,28.9,-59,19.2,-63.9,7.2C-68.7,-4.9,-71.2,-19.2,-67,-31.5C-62.8,-43.7,-51.9,-53.8,-39.6,-58.1C-27.4,-62.3,-13.7,-60.8,0,-60.9C13.8,-61,27.5,-62.6,35.2,-56.2Z"
                transform="translate(100 100)"
              />
            </svg>
          </Parallax>
        </div>

        <div className="splash" style={{ background }} />

        <motion.div className="card font-['Oswald'] relative" variants={cardVariantsA}>
          <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.3 }}>
            <motion.div variants={cardVariantsScaled}>
              <img src="images/questioned.png" alt="Miracle - Medical Consulting" className="w-[400px]" />
            </motion.div>
          </motion.div>

          <div className="flex flex-col fontMax" style={{ lineHeight: 1.2 }}>
            <span>{t("question3")}</span>
            <div className="stack" style={{ "--stacks": 3 }}>
              <span style={{ "--index": 0 }} className="fontMax">
                {" "}
                {t("question3a")}
              </span>
              <span style={{ "--index": 1 }} className="fontMax">
                {" "}
                {t("question3a")}
              </span>
              <span style={{ "--index": 2 }} className="fontMax">
                {" "}
                {t("question3a")}
              </span>
            </div>
          </div>
        </motion.div>
      </motion.div>

      <motion.section
        ref={refAboutUs}
        initial="hidden"
        animate={controlsAboutUs}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5 }}
        className="flex justify-center items-center bg-center bg-no-repeat bg-cover mt-24"
      >
        {isMobile ? (
          <div className="my-24 mx-6 w-full">
            <section className="mb-32 relative">
              <motion.div
                className={`cardAnimated}`}
                initial={{
                  opacity: 0,
                  x: items[0] % 2 === 0 ? 50 : -50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    type: "spring",
                    bounce: 0.4,
                    duration: 0.8,
                  },
                }}
                viewport={{ once: true }}
              >
                <img src="images/logo.png" alt="Miracle - Medical Consulting" className="w-[100px]" style={{ margin: "0 auto -50px" }} />
                <div class="block rounded-3xl bg-[hsla(0,0%,100%,0.95)] px-2 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] md:py-16 md:px-6">
                  <p class="inline-block pt-4 rounded px-6 text-xl text-master-100 font-roboto font-light leading-normal">{items[0]}</p>
                </div>
              </motion.div>

              <div
                className="relative my-4 overflow-hidden bg-cover bg-no-repeat bg-[50%] h-[500px] rounded-3xl"
                style={{
                  backgroundImage: `url(/images/sunset-istanbul-city-with-view-golden-horn-bay.jpg)`,
                  backgroundSize: "cover",
                  height: "100vh",
                }}
              >
                <div className="absolute flex justify-center items-center w-full py-16 font-bold flex-col z-40">
                  <div className="relative z-10 flex flex-col justify-center items-center">
                    <h1 className="uppercase text-secondary-100 text-5xl">Miracle</h1>
                    <span className="uppercase text-master-100 text-lg">Medical Consulting</span>
                  </div>
                </div>
              </div>

              <motion.div
                className={`cardAnimated}`}
                initial={{
                  opacity: 0,
                  x: items[1] % 2 === 0 ? 50 : -50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    type: "spring",
                    bounce: 0.4,
                    duration: 0.8,
                  },
                }}
                viewport={{ once: true }}
              >
                <div class="block rounded-3xl bg-[hsla(0,0%,100%,0.95)] px-2 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] md:py-16 md:px-6">
                  <p class="inline-block rounded px-6 text-xl text-master-100 font-roboto font-light leading-normal">{items[1]}</p>
                </div>
              </motion.div>
            </section>
          </div>
        ) : (
          <div className="my-24 mx-12 w-full max-w-[1600px]">
            <section className="mb-32 relative">
              <div
                className="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] h-[500px] rounded-3xl"
                style={{
                  backgroundImage: `url(/images/sunset-istanbul-city-with-view-golden-horn-bay.jpg)`,
                  backgroundSize: "cover",
                  height: "100vh",
                }}
              ></div>

              <div className="w-full flex flex-col absolute top-0 p-[30px] h-full">
                {items.map((item, i) => (
                  <CardAnimated key={i} text={item} index={i} />
                ))}
              </div>
            </section>
          </div>
        )}
      </motion.section>

      <motion.section
        className="relative flex flex-col mb-32 lg:mb-0"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0 }}
        variants={cardVariantsFade}
      >
        <div className="relative z-20">
          <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#eef9ff"
              fill-opacity="1"
              d="M0,128L80,154.7C160,181,320,235,480,234.7C640,235,800,181,960,138.7C1120,96,1280,64,1360,48L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg>

          <div className="flex mx-auto w-full bg-[#eef9ff]">
            <div className="max-w-[760px] m-auto flex flex-col justify-center w-full relative items-center">
              <img
                src="images/logo.png"
                alt="Miracle - Medical Consulting"
                className="w-[100px] absolute"
                style={{ margin: "0 auto", zIndex: 10, top: "-170px" }}
              />
              <div className="flex flex-col m-auto max-w-6xl">
                <div className="mx-12 md:px-6">
                  <div className="relative z-10 flex mb-6 flex-col justify-center items-center">
                    <h1 className="uppercase text-secondary-100 text-2xl">{t("aboutTitle")}</h1>
                  </div>

                  <p className="font-roboto text-secondary-900">{t("aboutText1")}</p>
                  <br />
                  <p className="font-roboto text-secondary-900">{t("aboutText2")}</p>

                  <br />
                  <p className="text-lg font-roboto text-primary-100" style={{ lineHeight: "1" }}>
                    {t("aboutText3")}
                  </p>

                  <p className="font-roboto m-2 text-secondary-900">{t("aboutText3a")}</p>
                  <p className="font-roboto m-2 text-secondary-900">{t("aboutText3b")}</p>
                  <p className="font-roboto m-2 text-secondary-900">{t("aboutText3c")}</p>

                  <br />
                  <p className="font-roboto text-secondary-900">{t("aboutText4")}</p>

                  <br />
                </div>
              </div>
            </div>
          </div>

          <svg className="rotate-180 mt-[-2px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#eef9ff"
              fill-opacity="1"
              d="M0,224L80,213.3C160,203,320,181,480,192C640,203,800,245,960,266.7C1120,288,1280,288,1360,288L1440,288L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg>
        </div>
      </motion.section>

      <motion.section
        ref={refCTATitle}
        initial="hidden"
        animate={controlsCTATitle}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5 }}
        className="flex flex-col justify-center items-center bg-center bg-no-repeat bg-cover"
      >
        <h1
          className="text-master-200 font-['Ubuntu_Condensed'] text-center uppercase text-[50px] lg:text-[80px] py-12 font-medium"
          style={{ lineHeight: "1" }}
        >
          {t("whyUsTitle")}
        </h1>
        <Timeline className="timeline" />
      </motion.section>

      {/*                            */}
      {/* 🟩 Hospitals               */}
      {/*                            */}
      <motion.section
        ref={refOurServices}
        initial="hidden"
        animate={controlsOurServices}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5 }}
        className="flex flex-col justify-center items-center bg-center bg-no-repeat bg-cover"
      >
        <div className="flex mx-auto">
          <ProjectCards />
        </div>
      </motion.section>

      <motion.section
        className="flex flex-col justify-center items-center bg-center bg-no-repeat bg-cover"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0 }}
        variants={cardVariantsFade}
      >
        <div className="flex flex-col m-auto max-w-6xl">
          <div className="mx-12 md:px-6">
            <h1 className="text-2xl lg:text-[50px] text-secondary-900 font-['Ubuntu_Condensed'] text-center" style={{ lineHeight: 1.1 }}>
              {t("doctorsIntro")}
            </h1>

            <div className="bg-primary-100 m-auto mt-12" style={{ width: "100px", height: "2px" }}></div>
          </div>

          <Doctors />
        </div>
      </motion.section>

      <motion.div
        className="relative flex flex-col mb-32 lg:mb-0"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0 }}
        variants={cardVariantsFade}
      >
        <TestimonialSection />
      </motion.div>

      <motion.div
        className="relative min-h-[100svh] mb-24 flex flex-col"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0 }}
        variants={cardVariantsScaled}
      >
        <div className="absolute w-full parallaxOnVisible z-10" ref={parallaxRef}>
          <div className="flex flex-col justify-center items-center h-[100svh] w-full">
            <Parallax translateY={[60, -60]} speed={50}>
              <img src="images/elementStar1.png" className="left-[-300px] relative opacity-15" width={300} alt="Miracle - Medical Consulting" />
            </Parallax>

            <Parallax translateY={[120, -120]} speed={10}>
              <img src="images/elementStar2.png" className="left-[300px] relative opacity-15" width={300} alt="Miracle - Medical Consulting" />
            </Parallax>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center bg-center bg-no-repeat bg-cover h-[100svh] mb-[300px] relative z-20">
          <div className="max-w-[760px] text-primary-100 m-12 flex flex-col justify-center items-center">
            <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled}>
              <h1 className="text-[360px] font-roboto font-bold" style={{ lineHeight: 0.8, textShadow: "10px 10px 0px #404040" }}>
                6
              </h1>
            </motion.div>

            <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled}>
              <img src="images/team.png" width={320} alt="Miracle - Medical Consulting" />
            </motion.div>
            <h1 className="text-[50px] font-['Ubuntu_Condensed'] text-center" style={{ lineHeight: 1 }}>
              {t("thankYouForService")}
            </h1>
          </div>
        </div>
      </motion.div>

      {/*                            */}
      {/* 🟩 Call now button         */}
      {/*                            */}
      <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0 }} variants={cardVariantsScaled}>
        <div className="container mx-auto px-2 md:px-12 xl:px-32 relative">
          <div className="text-center">
            <div className="flex css-selector flex-col items-center rounded-[60px] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] shadow-black/20 md:py-16 md:px-12">
              <img src="images/callnow.png" alt="Miracle - Medical Consulting" className="w-[300px] absolute top-[-250px]" />
              <h1 className="mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl">
                <span className="text-primary-300">{t("callUsNow")}</span>
              </h1>

              <div className="flex flex-col justify-center items-center w-full">
                <MagentButton title={t("phoneNumberShort")} colorCode={"#02AED9"} linkAddress={t("phoneNumberCode")} isLarge={true} />
              </div>
            </div>

            <div className="flex items-center justify-center my-12">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="mx-2 cursor-pointer inline-block rounded-full px-6 py-2 text-lg font-medium uppercase leading-normal text-main-200 font-['Ubuntu_Condensed'] opacity-75 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-primary-200 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200"
              >
                <a href="https://www.facebook.com/miracle.medical.consulting" target="_blank" rel="noreferrer">
                  <svg className="w-[36px] h-[36px] fill-[#02AED9]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
                  </svg>
                </a>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="mx-2 cursor-pointer inline-block rounded-full px-6 py-2 text-lg font-medium uppercase leading-normal text-main-200 font-['Ubuntu_Condensed'] opacity-75 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-primary-200 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200"
              >
                <a href="mailto:gulkajan@yahoo.com" target="_blank" rel="noreferrer">
                  gulkajan@yahoo.com
                </a>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>

      {/*                            */}
      {/* 🟨 Button                  */}
      {/*                            */}
      {showScrollDownButton && (
        <div className="fixed flex justify-center w-full z-20 bottom-12">
          <motion.button onClick={scrollToBelowSection} className="floatingArrow">
            <svg className="w-[50px] h-[50px] fill-[#fff]" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
            </svg>
          </motion.button>
        </div>
      )}

      <motion.div className="progress-bar" style={{ scaleX: scrollYProgress }} />
    </div>
  );
};

export default Home;
