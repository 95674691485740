import { useEffect, useRef, useState } from "react";

export const BackgroundGradientAnimation = ({
  gradientBackgroundStart = "#00a5e7",
  gradientBackgroundEnd = "#E8E8E8",
  firstColor = "174, 238, 255",
  secondColor = "220, 248, 255",
  thirdColor = "0, 165, 231",
  fourthColor = "#00A5E7",
  fifthColor = "#00a5e7",
  pointerColor = "117, 182, 226",
  size = "80%",
  size2 = "160%",
  blendingValue = "hard-light",
  children,
  className,
  // interactive = true,
  containerClassName,
}) => {
  const interactiveRef = useRef(null);

  const [curX, setCurX] = useState(0);
  const [curY, setCurY] = useState(0);
  const [tgX, setTgX] = useState(0);
  const [tgY, setTgY] = useState(0);

  useEffect(() => {
    document.body.style.setProperty("--gradient-background-start", gradientBackgroundStart);
    document.body.style.setProperty("--gradient-background-end", gradientBackgroundEnd);
    document.body.style.setProperty("--first-color", firstColor);
    document.body.style.setProperty("--second-color", secondColor);
    document.body.style.setProperty("--third-color", thirdColor);
    document.body.style.setProperty("--fourth-color", fourthColor);
    document.body.style.setProperty("--fifth-color", fifthColor);
    document.body.style.setProperty("--pointer-color", pointerColor);
    document.body.style.setProperty("--size", size);
    document.body.style.setProperty("--size2", size2);
    document.body.style.setProperty("--blending-value", blendingValue);
  }, [
    gradientBackgroundStart,
    gradientBackgroundEnd,
    firstColor,
    secondColor,
    thirdColor,
    fourthColor,
    fifthColor,
    pointerColor,
    size,
    size2,
    blendingValue,
  ]);

  useEffect(() => {
    function move() {
      if (!interactiveRef.current) {
        return;
      }
      setTgX(curX + (tgX - curX) / 20);
      setTgY(curY + (tgY - curY) / 20);
      setCurX(curX + (tgX - curX) / 20);
      setCurY(curY + (tgY - curY) / 20);
      interactiveRef.current.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
    }

    move();
  }, [tgX, tgY, curX, curY]);

  // const handleMouseMove = (event) => {
  //   if (interactiveRef.current) {
  //     const rect = interactiveRef.current.getBoundingClientRect();
  //     setTgX(event.clientX - rect.left);
  //     setTgY(event.clientY - rect.top);
  //   }
  // };

  return (
    <div
      className={`h-screen w-full relative overflow-hidden top-0 left-0 ${containerClassName}`}
      style={{
        height: "calc(100vh - 60px)",
        backgroundImage: "url(images/gradient-background--blurred--vibrant--white--blue-color--best-web-design.jpg",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <svg className="hidden">
        <defs>
          <filter id="blurMe">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
      <div className={className}>{children}</div>
      <div className="gradients-container [filter:url(#blurMe)_blur(40px)] h-full w-full">
        <div
          className={`absolute [background:radial-gradient(circle_at_center,_var(--first-color)_0,_var(--first-color)_50%)_no-repeat] 
    [mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)] 
    [transform-origin:center_center] animate-first opacity-100`}
        ></div>
        <div
          className={`absolute [background:radial-gradient(circle_at_center,_rgba(var(--second-color),_0.8)_0,_rgba(var(--second-color),_0)_50%)_no-repeat] 
    [mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(20%-var(--size)/2)] 
    [transform-origin:calc(50%-400px)] animate-second opacity-100`}
        ></div>
        <div
          className={`absolute [background:radial-gradient(circle_at_center,_rgba(var(--third-color),_0.8)_0,_rgba(var(--third-color),_0)_50%)_no-repeat] 
    [mix-blend-mode:var(--blending-value)] w-[var(--size2)] h-[var(--size2)] top-[calc(50%-var(--size2)/2)] left-[calc(80%-var(--size2)/2)] 
    [transform-origin:calc(50%+700px)] animate-third opacity-70`}
        ></div>
        <div
          className={`absolute [background:radial-gradient(circle_at_center,_rgba(var(--fourth-color),_0.8)_0,_rgba(var(--fourth-color),_0)_50%)_no-repeat] 
    [mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)] 
    [transform-origin:calc(50%-200px)] animate-fourth opacity-70`}
        ></div>
        <div
          className={`absolute [background:radial-gradient(circle_at_center,_rgba(var(--fifth-color),_0.8)_0,_rgba(var(--fifth-color),_0)_50%)_no-repeat] 
    [mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)] 
    [transform-origin:calc(50%-800px)_calc(50%+800px)] animate-fifth opacity-100`}
        ></div>

        {/* {interactive && (
          <div
            ref={interactiveRef}
            onMouseMove={handleMouseMove}
            className={`absolute [background:radial-gradient(circle_at_center,_rgba(var(--pointer-color),_0.8)_0,_rgba(var(--pointer-color),_0)_50%)_no-repeat] 
      [mix-blend-mode:var(--blending-value)] w-full h-full -top-1/2 -left-1/2 opacity-70`}
          ></div>
        )} */}
      </div>
    </div>
  );
};
