import { useEffect } from "react";
import { motion } from "framer-motion";

const MotionModal = ({ isOpen, onClose, hospitalData }) => {
  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === "Escape" && isOpen) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscapeKey);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
      document.body.style.overflow = "auto";
    };
  }, [isOpen, onClose]);

  return (
    <div>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.75)",
            zIndex: "999999",
          }}
          className="backdrop-blur-[30px]"
          onClick={onClose}
        >
          <div className="fixed top-[20px] w-full flex justify-center items-center">
            <button onClick={onClose} className="">
              <motion.div whileHover={{ scale: 1.1 }} transition={{ type: "spring", stiffness: 400, damping: 10 }}>
                <svg class="w-[50px] h-[50px] fill-[#ccc]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"></path>
                </svg>
              </motion.div>
            </button>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30 }}
            transition={{ delay: 0.3, duration: 0.6 }}
            style={{
              background: "white",
              padding: "10px",
              margin: "10px",
              borderRadius: "30px",
              maxHeight: "calc(100% - 120px)",
              marginTop: "60px",
            }}
            className="flex flex-col max-w-[960px] w-full relative overflow-auto"
            id="scrollbar2"
            onClick={(e) => e.stopPropagation()}
          >
            <div class="justify-start inline-block h-full relative overflow-visible">
              <div
                style={{ backgroundImage: `url(${hospitalData["imageBanner"]})`, height: "300px" }}
                className="w-full rounded-xl rounded-tr-[30px] rounded-tl-[30px] shadow-lg bg-center bg-cover"
              ></div>
              <h1 className="text-primary-200 text-4xl font-['Ubuntu_Condensed'] px-12 py-6 text-center mt-4">{hospitalData["title"]}</h1>
              <div class="p-6">
                <p class="mb-6">{hospitalData["fullDescription"]}</p>
              </div>
              {hospitalData["imageInterior"] !== "" && (
                <img
                  className="rounded-xl rounded-br-[30px] rounded-bl-[30px] shadow-lg m-auto"
                  src={`${hospitalData["imageInterior"]}`}
                  alt={`${hospitalData["title"]}`}
                />
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default MotionModal;
