import React, { useState } from "react";
import { motion } from "framer-motion";
import QuoteModal from "../elements/QuoteModal";
import { useTranslation } from "react-i18next";

const TestimonialSection = () => {
  const { t } = useTranslation();

  const data = [
    {
      title: t("testimonial1Title"),
      content: t("testimonial1Intro"),
      fullContent: t("testimonial1Full"),
      organization: "Istiniye University Liv",
      alignment: "left",
    },
    {
      title: t("testimonial2Title"),
      content: t("testimonial2Intro"),
      fullContent: t("testimonial2Full"),
      organization: "Koç University client",
      alignment: "right",
    },
  ];

  const [isModalOpen, setModalOpen] = useState(false);
  const [clientData, setclientData] = useState({});

  const openModal = (item) => {
    setModalOpen(true);
    setclientData(item);
  };

  const closeModal = () => {
    setModalOpen(false);
    setclientData({});
  };

  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
      scale: 0.9,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  return (
    <div className="relative z-20">
      <svg className="mb-[-2px] min-h-[300px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#14242d"
          fill-opacity="1"
          d="M0,96L80,122.7C160,149,320,203,480,213.3C640,224,800,192,960,176C1120,160,1280,160,1360,160L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>

      <div className="flex mx-auto w-full bg-[#14242d]">
        <div className="max-w-[760px] m-auto flex flex-col justify-center w-full relative items-center">
          <img
            src="images/love.png"
            alt="Miracle - Medical Consulting"
            className="w-[300px] absolute"
            style={{ margin: "0 auto -50px", zIndex: 10, top: "-270px" }}
          />

          <div class="blockquote-wrapper mt-4">
            {data.map((item, index) => (
              <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled}>
                <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 400, damping: 10 }}>
                  <button onClick={() => openModal(item)} className="hover:text-primary-200 mb-12">
                    <div className={`blockquote ${item.alignment === "right" ? "right" : ""}`}>
                      <h1>
                        {item.title} <span className="text-primary-300">{item.content}</span>
                      </h1>
                      <div className="quoteTitle">
                        <span>{t("hospitalized")}</span>
                        <h4>{item.organization}</h4>
                      </div>
                    </div>
                  </button>
                </motion.div>
              </motion.div>
            ))}
          </div>

          <QuoteModal isOpen={isModalOpen} onClose={closeModal} clientData={clientData}></QuoteModal>
        </div>
      </div>

      <svg className="rotate-180 mt-[-2px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#14242d"
          fill-opacity="1"
          d="M0,160L80,176C160,192,320,224,480,229.3C640,235,800,213,960,176C1120,139,1280,85,1360,58.7L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};

export default TestimonialSection;
